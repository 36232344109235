export const booking = {
  getBookingsForAdmin: {
    url: "admin/booking/getAdminStatusBookings",
    method: "GET",
    params: null,
  },
  getBookingsForVendor: {
    url: "admin/booking/getVendorStatusBookings",
    method: "GET",
    params: null,
  },
  approveBooking: {
    url: "admin/booking/approveBookings",
    method: "POST",
    data: null,
  },
  rejectBooking: {
    url: "admin/booking/rejectBookings",
    method: "POST",
    data: null,
  },
  initSecondPayment: {
    url: "booking/initSecondPayment",
    method: "GET",
    params:null,
  },
};
