<template>
  <Layout>
    <b-tabs v-on:activate-tab="tabChanged">
      <b-tab title="Pending For Approval" active>
        <div v-if="loading" style="text-align: center; margin-top: 28px">
          <pulse-loader :loading="loading"></pulse-loader>
        </div>
        <div v-else>
          <div v-if="tab == 0" class="row">
            <div
              class="col-lg-4 col-md-6 col-sm-6"
              v-for="item in data"
              :key="item._id"
            >
              <BookingCardVendor
                userType="vendor"
                v-on:loadData="loadBooking"
                :data="item"
              />
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Pending For Admin Approval">
        <div v-if="loading" style="text-align: center; margin-top: 28px">
          <pulse-loader :loading="loading"></pulse-loader>
        </div>
        <div v-else>
          <div v-if="tab == 1" class="row">
            <div
              class="col-lg-4 col-md-6 col-sm-6"
              v-for="item in data"
              :key="item._id"
            >
              <BookingCardVendor
                userType="vendor"
                v-on:loadData="
                  page = 1;
                  loadBooking();
                "
                :data="item"
              />
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Approved By Admin">
        <div v-if="loading" style="text-align: center; margin-top: 28px">
          <pulse-loader :loading="loading"></pulse-loader>
        </div>
        <div v-else>
          <div v-if="tab == 2" class="row">
            <div
              class="col-lg-4 col-md-6 col-sm-6"
              v-for="item in data"
              :key="item._id"
            >
              <BookingCardVendor
                userType="vendor"
                v-on:loadData="loadBooking"
                :data="item"
              />
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Rejected">
        <div v-if="loading" style="text-align: center; margin-top: 28px">
          <pulse-loader :loading="loading"></pulse-loader>
        </div>
        <div v-else>
          <div v-if="tab == 3" class="row">
            <div
              class="col-lg-4 col-md-6 col-sm-6"
              v-for="item in data"
              :key="item._id"
            >
              <BookingCardVendor
                userType="vendor"
                v-on:loadData="loadBooking"
                :data="item"
              />
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Rejected By Admin">
        <div v-if="loading" style="text-align: center; margin-top: 28px">
          <pulse-loader :loading="loading"></pulse-loader>
        </div>
        <div v-else>
          <div v-if="tab == 4" class="row">
            <div
              class="col-lg-4 col-md-6 col-sm-6"
              v-for="item in data"
              :key="item._id"
            >
              <BookingCardVendor
                userType="vendor"
                v-on:loadData="loadBooking"
                :data="item"
              />
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
    <div v-if="data.length" class="d-flex justify-content-end">
      <b-pagination
        :value="page"
        @change="pageChange"
        :total-rows="rows"
        :per-page="perPage"
        pills
      ></b-pagination>
    </div>
    <div v-else style="text-align: center; margin-top: 18px">
      <span v-if="!loading"> No Data </span>
    </div>
  </Layout>
</template>

<script>
import BookingCardVendor from "@/components/booking_card/BookingCardVendor";
import Layout from "../../layouts/main";
import { booking } from "@/config/api/booking";
export default {
  data() {
    return {
      data: {},
      loading: false,
      status: 0,
      page: 1,
      tab: 0,
      rows: 0,
      perPage: 10,
    };
  },
  components: {
    Layout,
    BookingCardVendor,
  },
  created() {
    this.loadBooking();
  },
  methods: {
    tabChanged(val) {
      this.tab = val;
      this.page = 1;
      this.loadBooking();
    },
    loadBooking() {
      const api = booking.getBookingsForVendor;
      api.params = {
        status: this.tab,
        perPage: this.perPage,
        page: this.page,
      };
      this.loading = true;
      this.generateAPI(api)
        .then((res) => {
          this.data = res.data.docs;
          this.rows = res.data.totalDocs;
        })
        .catch((err) => {
          console.log(err.response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    pageChange(val) {
      this.page = val;

      this.loadBooking();
    },
  },
};
</script>

<style></style>
